import consumer from "./consumer"

var slug = document.baseURI.split("/")[3]

if(document.baseURI.match(/talkevents/)){
  var usuario_logado = JSON.parse(decodeURIComponent(getCookie("usuario_live_events")))
  
  consumer.subscriptions.create({channel: "AjudaChannel", talk_evento: slug, idEvento: usuario_logado.evento_id, idUsuario: usuario_logado.id}, {
    received(usuarios) {
      if(usuarios){
        if(this.getUsuario().funcao == "Funcionario"){
          if (usuarios.length > 0) {
            $('.box-usuarios-aovivo').empty();

            usuarios.forEach(usuario => {
              if(usuario['visualizado'] == null && usuario['recebeu'] == this.getUsuario().id){
                $('.box-usuarios-aovivo').append(`
                <div class="lista-contatos" onclick="abriu_chamado('${usuario['id']}')">
                  <div class="pessoa-contato row">
                      <div class="thumb-pessoa" style="background-color: ${usuario['color']}">${this.converterNome(usuario['nome'])}</div>
                      <div class="nick-pessoa">${usuario['nome'].substring(0,10)}</div>
                      <div class="marcador"></div>
                  </div>
                </div>
              `)
              }else{
                if(usuario['recebeu'] == this.getUsuario().id){
                  $('.box-usuarios-aovivo').append(`
                  <div class="lista-contatos" onclick="abriu_chamado('${usuario['id']}')">
                    <div class="pessoa-contato row">
                        <div class="thumb-pessoa" style="background-color: ${usuario['color']}">${this.converterNome(usuario['nome'])}</div>
                        <div class="nick-pessoa font-weight-bold">${usuario['nome'].substring(0,10)}</div>
                        <div class="marcador"></div>
                    </div>
                  </div>
                `)
                }
                
              }
            });
          }
        }
      }
      
    },

    converterNome(nomeUsuario) {
      const nomes = nomeUsuario.split(' ')
      const subsImagem = `${nomes[0].substr(0, 1)}${nomes[nomes.length - 1].substr(0, 1)}`
      return subsImagem
    },

    getUsuario(){
      try{
        let usuario = JSON.parse(localStorage.getItem("usuario"))
        return usuario;
      }
      catch(e){ return undefined }
    }
  });

  function getCookie(name){
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
}

