import consumer from "./consumer"

if(document.baseURI.match(/talkevents/)){
  consumer.subscriptions.create("GrupoChannel", {
    received(data) {
      var usuario = this.dadosUsuario()

      if(this.getGrupo()){
        if(data.mandou == usuario.id && data.grupo == this.getGrupo().id){
          $('.mensagem_user_active').val("")
          $('.file_grupo_chat').val("")
          this.mandouMensagem(data.nome_mandou, data.content, data.id)
        }else{
          if(data.grupo == this.getGrupo().id){
            this.recebeuMensagem(data.nome_mandou, data.content, data.id)
          }
        }
      }

      var objDiv = document.getElementById("box-dialog-grupo");
      objDiv.scrollTop = objDiv.scrollHeight;
    },

    mandouMensagem(usuario, mensagem, id){
      var conditions = ["jpg", "jpeg", "png", "gif"]
      var imagem = conditions.some(el => mensagem.includes(el));
      var conteudo_html = `<div class="chat-dialog-person2 grupo_chat_${id}"><img width="10" height="10" onclick="apagarMensagemGrupo('${id}')" src="https://pt.seaicons.com/wp-content/uploads/2016/06/Start-Menu-Recycle-Bin-Full-icon.png"><p class="nick-pessoa font-weight-bold mb-2">${usuario}</p>`

      if(imagem){
        conteudo_html += `<a target="_blank" href="${mensagem}"><img src="${mensagem}" class="w-100 rounded d-block mb-4 shadow"></a></div>`
      }else if(mensagem.includes('.pdf')){
        conteudo_html += `<img src=" https://liveflix.s3.us-east-2.amazonaws.com/PLATAFORMA/TALK+EVENTS/BASE/pdf.png" style="width:25%;display:block"><a style="word-break: break-all" target="_blank" href="${mensagem}">${mensagem}</a></div>`
      }else{
        conteudo_html += `<span>${mensagem}</span></div>`
      }

      $('.box-dialog').append(conteudo_html)
    },

    recebeuMensagem(usuario, mensagem, id){
      var conditions = ["jpg", "jpeg", "png", "gif"]
      var imagem = conditions.some(el => mensagem.includes(el));
      var conteudo_html = `<div class="chat-dialog-person1 grupo_chat_${id}"><p class="nick-pessoa font-weight-bold mb-2">${usuario}</p>`

      if(imagem){
        conteudo_html += `<a target="_blank" href="${mensagem}"><img src="${mensagem}" class="w-100 rounded d-block mb-4 shadow"></a></div>`
      }else if(mensagem.includes('.pdf')){
        conteudo_html += `<img src=" https://liveflix.s3.us-east-2.amazonaws.com/PLATAFORMA/TALK+EVENTS/BASE/pdf.png" style="width:25%;display:block"><a style="word-break: break-all" target="_blank" href="${mensagem}">${mensagem}</a></div>`
      }else{
        conteudo_html += `<span>${mensagem}</span></div>`
      }

      $('.box-dialog').append(conteudo_html)
    },

    getGrupo(){
      try{
        let grupo = JSON.parse(localStorage.getItem("grupo"))
        return grupo;
      }
      catch(e){ return undefined }
    },

    dadosUsuario(){
      try{
        let usuario = JSON.parse(localStorage.getItem("usuario"))
        return usuario;
      }
      catch(e){ return undefined }
    }
  });
}