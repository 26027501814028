import consumer from "./consumer"

var slug = document.baseURI.split("/")[3]

if(document.baseURI.match(/talkevents/)){
  var usuario_logado = JSON.parse(decodeURIComponent(getCookie("usuario_live_events")))
  consumer.subscriptions.create({channel: "UsuarioChannel", talk_evento: slug, idEvento: usuario_logado.evento_id}, {
    
    received(usuarios) {
      if(this.getUsuario().funcao != "Funcionario"){
        if(usuarios) {
          $('.box-usuarios-aovivo').empty();
          let usuariosRelacionado = []
          let usuariosNaoRelacionado = []
          let usuariosNaoRelacionadoOnline = []
          let nomesUsuariosInclusos = []

          usuarios.forEach(usuario => {
            let dadosUsuario = {
              id: usuario.id, 
              nome: usuario.nome, 
              foto: usuario.foto, 
              color: usuario.color, 
              visualizado: usuario.visualizado
            }

            if(usuario.id != usuario_logado.id){
              if(usuario.recebeu){
                if(usuario.recebeu == this.getUsuario().id){
                  if(usuario.visualizado == false){
                    usuariosRelacionado.push(usuario.nome)
                    this.usuariosOnlineMandouMSG(usuario)
                  }else{
                    usuariosRelacionado.push(usuario.nome)
                    this.usuariosOnlineNaoMandouMSG(usuario)
                  }
                }else{
                  usuariosNaoRelacionado.push(dadosUsuario)
                }
              }else{
                usuariosRelacionado.push(usuario.nome)
                this.usuariosOnlineNaoMandouMSG(usuario)
              }
            }
          });

          usuariosNaoRelacionado.forEach(usuarioNaoRelacionado => {
            if(!usuariosRelacionado.includes(usuarioNaoRelacionado.nome)){
              if(!nomesUsuariosInclusos.includes(usuarioNaoRelacionado.nome)){
                usuariosNaoRelacionadoOnline.push(usuarioNaoRelacionado)
                nomesUsuariosInclusos.push(usuarioNaoRelacionado.nome)
              }
            }
          });

          usuariosNaoRelacionadoOnline.forEach(usuario => {
            this.usuariosOnlineNaoMandouMSG(usuario)
          });
        }
      }
    },
    
    usuariosOnlineMandouMSG(usuario){
      if(usuario.foto){
        $('.box-usuarios-aovivo').append(`
          <div class="lista-contatos" onclick="batepapo('${usuario.id}')">
            <div class="pessoa-contato row">
                <div class="thumb-pessoa" style="background-color: #e5e3e2;"><img src="${usuario.foto}" class="img-fluid"/></div>
                <div class="nick-pessoa font-weight-bold">${usuario.nome.substring(0,10)}</div>
                <div class="marcador" id="marcador-${usuario.id}"></div>
            </div>
          </div>
        `)
      }else{
        $('.box-usuarios-aovivo').append(`
        <div class="lista-contatos" onclick="batepapo('${usuario.id}')">
          <div class="pessoa-contato row">
              <div class="thumb-pessoa" style="background-color: ${usuario.color}">${this.converterNome(usuario.nome)}</div>
              <div class="nick-pessoa font-weight-bold">${usuario.nome.substring(0,10)}</div>
              <div class="marcador" id="marcador-${usuario.id}"></div>
          </div>
        </div>
      `) 
      }
    },

    usuariosOnlineNaoMandouMSG(usuario){
      if(usuario.foto){
        $('.box-usuarios-aovivo').append(`
          <div class="lista-contatos" onclick="batepapo('${usuario.id}')">
            <div class="pessoa-contato row">
                <div class="thumb-pessoa" style="background-color: #e5e3e2;"><img src="${usuario.foto}" class="img-fluid"/></div>
                <div class="nick-pessoa">${usuario.nome.substring(0,10)}</div>
            </div>
          </div>
        `)
      }else{
        $('.box-usuarios-aovivo').append(`
        <div class="lista-contatos" onclick="batepapo('${usuario.id}')">
          <div class="pessoa-contato row">
              <div class="thumb-pessoa" style="background-color: ${usuario.color}">${this.converterNome(usuario.nome)}</div>
              <div class="nick-pessoa">${usuario.nome.substring(0,10)}</div>
          </div>
        </div>
      `) 
      }
    },

    converterNome(nomeUsuario) {
      const nomes = nomeUsuario.split(' ')
      const subsImagem = `${nomes[0].substr(0, 1)}${nomes[nomes.length - 1].substr(0, 1)}`
      return subsImagem
    },

    getUsuario(){
      try{
        let usuario = JSON.parse(localStorage.getItem("usuario"))
        return usuario;
      }
      catch(e){ return undefined }
    }
  });

  function getCookie(name){
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
}