import consumer from "./consumer"

if(document.baseURI.match(/talkevents/)){
  consumer.subscriptions.create("UsuarioBloqueadoChannel", {
    received(usuario) {
      if(this.getUsuario()){
        if(usuario.id == this.getUsuario().id){
          $.ajax({
            type: 'GET',
            url: '/usuarios/' + usuario.id + '.json',
            success: function(usuarioRetornado){
              setUsuario(usuarioRetornado)

              $(".box-participantes-grupo").hide();
              $(".modal-talk-chatgroup").hide(); 

              const usuario = usuarioRetornado
              if(usuario.usuario_eventos){
                usuario.usuario_eventos.forEach(usuario_evento => {
                  if(usuario_evento.evento_id == dadosCookiesUsuarios().evento_id){
                    $('.lista-grupos').empty();
                    usuario_evento.grupo_usuario_eventos.forEach(grupo => {
                      if(!grupo.usuario_bloqueado){
                        $('.lista-grupos').append(`
                          <div class="item-grupo row" onclick="grupoBatepapo(${grupo.grupo_id})">
                            <div class="thumb-grupo col col-sm-3">
                                <img class="" src="${grupo.grupo_logo}"/>
                            </div>
                            <div class="nick-grupo col col-sm-8 font-weight-bold">${grupo.grupo_nome}</div>
                          </div>
                        `)
                      }
                    });
                  }
                });
              }
            }
          }) 
        }
      }
    },

    getUsuario(){
      try{
        let usuario = JSON.parse(localStorage.getItem("usuario"))
        return usuario;
      }
      catch(e){ return undefined }
    },

    setUsuario(usuario){
      localStorage.setItem("usuario", JSON.stringify(usuario));
    },

    dadosCookiesUsuarios(){
      return JSON.parse(decodeURIComponent(this.getCookie("usuario_live_events")))
    },

    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }
  });
}
