import consumer from "./consumer"

if(document.baseURI.match(/talkevents/)){
  consumer.subscriptions.create({channel: "RoomChannel"}, {
    received(data) {
      var usuario = this.dadosUsuario()

      if(this.getReciveMesseges()){
        if(data.mandou == usuario.id && data.recebeu == this.getReciveMesseges().id){
          $('.mensagem_user_active').val("")
          if(usuario.funcao == "Funcionario"){
            this.mandouAtendente(data.content, data.id)
          }else{
            this.mandouMensagem(data.content)
          }
        }else{
          if(data.recebeu == usuario.id && data.mandou == this.getReciveMesseges().id){
            this.recebeuMensagem(data.content, data.id)
          }
        }
      }else{
        if(this.getAtendente()){
          if(data.mandou == usuario.id && data.recebeu == this.getAtendente().id){
            $('.mensagem_user_active').val("")
            this.mandouMensagem(data.content)
          }else{
            if(data.recebeu == usuario.id && data.mandou == this.getAtendente().id){
              this.recebeuMensagem(data.content, data.id)
            }
          }
        }
      }
 
      var objDiv = document.getElementById("box-dialog");
      objDiv.scrollTop = objDiv.scrollHeight;
    },

    mandouMensagem(mensagem){
      $('.box-dialog').append(`
        <div class="chat-dialog-person2">
          <span>${mensagem}</span>
        </div>
      `)
    },

    mandouAtendente(mensagem, id){
      $('.box-dialog').append(`
        <div class="chat-dialog-person2 conversa_${id}">
          <img width="10" height="10" onclick="apagarMensagemUsuarios('${id}')" src="https://pt.seaicons.com/wp-content/uploads/2016/06/Start-Menu-Recycle-Bin-Full-icon.png">
          <span>${mensagem}</span>
        </div>
      `)
    },

    recebeuMensagem(mensagem, id){
      $('.box-dialog').append(`
        <div class="chat-dialog-person1 conversa_${id}">
          <span>${mensagem}</span>
        </div>
      `)
    },

    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },

    dadosUsuario(){
      try{
        let usuario = JSON.parse(localStorage.getItem("usuario"))
        return usuario;
      }
      catch(e){ return undefined }
    },

    getAtendente(){
      try{
        let atendente = JSON.parse(localStorage.getItem("atendente"))
        return atendente;
      }
      catch(e){ return undefined }
    },

    getReciveMesseges(){
      try{
        let recive_messege = JSON.parse(localStorage.getItem("recive_messeges"))
        return recive_messege;
      }
      catch(e){ return undefined }
    }
  });
}


