import consumer from "./consumer"

if(document.baseURI.match(/talkevents/)){
  consumer.subscriptions.create("UsuarioParticipanteChannel", {
    received(usuarios) {
      if(this.getGrupo()){
        $('.box-participantes').empty();

        if(this.getUsuario().funcao == null || this.getUsuario().funcao == ""){
          usuarios.forEach(usuario => {
            if(usuario.talk_evento_id == this.getGrupo().id){
              $('.box-participantes').append(`
                <div class="lista-contatos">
                  <div class="pessoa-contato row">
                      <div class="nick-pessoa font-weight-bold">${usuario.nome}</div>
                  </div> 
                </div>
              `);
            }
          });
        }else{
          usuarios.forEach(usuario => {
            if(usuario.talk_evento_id == this.getGrupo().id){
              aplicar_usuarios_participantes(usuario)
            }
          });
        }
        
      }
    },

    getUsuario(){
      try{
        let usuario = JSON.parse(localStorage.getItem("usuario"))
        return usuario;
      }
      catch(e){ return undefined }
    },

    getGrupo(){
      try{
        let grupo = JSON.parse(localStorage.getItem("grupo"))
        return grupo;
      }
      catch(e){ return undefined }
    },
  });

  function aplicar_usuarios_participantes(usuario){
    var nomeVerificado = ""
    var frase = ""
    var botao = ""

    if(usuario.bloqueado){
      var nomeVerificado = `<s>${usuario.nome}</s>`
      var frase = `Deseja DESBLOQUEAR esse participante?`
      var botao = `DESBLOQUEAR`
    }else{
      var nomeVerificado = `${usuario.nome}`
      var frase = `Deseja BLOQUEAR esse participante?`
      var botao = `BLOQUEAR`
    }

    $('.box-participantes').append(`
      <div class="lista-contatos">
        <div class="pessoa-contato row">
            <div class="nick-pessoa font-weight-bold" onclick="validarBoqueioUsuario('${usuario.id}')">${nomeVerificado}</div>
        </div>
        <div class="pop-bloqueio w-75 mt-2 bg-white p-4 position-relative usuario_ferencia_${usuario.id}" style="display: none;">
            <div class="fechar-bloqueio" onclick="validarBoqueioUsuario('${usuario.id}')" style="position:absolute;z-index:2; top: 0;right: 1vh;">X</div>
            ${frase}
            <button class="btn btn-secondary" onclick="confirmarBoqueioUsuario('${usuario.id}', '${usuario.grupo_usuario_evento_id}')">${botao}</button>
        </div> 
      </div>
    `);
  }
}
