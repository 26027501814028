import consumer from "./consumer"

if(document.baseURI.match(/talkevents/)){
  consumer.subscriptions.create("UploadFotoChannel", {
    received(usuario) {
      if(usuario.id == this.dadosUsuario().id){
        $('.foto-perfil').html(`<img src="${usuario.foto}" width="100px">`)
        $('.icon-user').html(`<img src="${usuario.foto}">`)
        $('.icon-user').append(`<p class="font-weight-bold username">PERFIL</p>`)
      }
    },

    dadosUsuario(){
      try{
        let usuario = JSON.parse(localStorage.getItem("usuario"))
        return usuario;
      }
      catch(e){ return undefined }
    }
  });
}

